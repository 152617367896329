import { useState } from 'react';
import cartClient from 'apps/commerce/cart/utils/cartClient';
import { useCart } from 'apps/commerce/cart';

const useCartCoupon = () => {
  const [couponCode, setCouponCode] = useState('');
  const [submittingCoupon, setSubmittingCoupon] = useState(false);
  const [couponError, setCouponError] = useState<string | null>(null);

  const { context, updateCartState } = useCart();
  const { cart } = context || {};
  const { coupon: appliedCoupon } = cart || {};
  const { couponCode: appliedCouponCode, percentOff: appliedPercentOff } = appliedCoupon || {};

  const buttonDisabled = !couponCode || submittingCoupon;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(event.target.value);
  };

  const submitCouponCode = () => {
    if (!couponCode || submittingCoupon) return;

    setSubmittingCoupon(true);
    setCouponError(null); // Reset any previous errors

    cartClient
      .validateCoupon(couponCode)
      .then((res) => {
        updateCartState({ taxes: cart?.taxes, ...res.data });
      })
      .catch((error) => {
        const errorCode = error?.response?.data?.code || 'unknown_error';
        setCouponError(errorCode);
      })
      .finally(() => {
        setSubmittingCoupon(false);
      });
  };

  const clearCoupon = () => {
    cartClient.clearCoupon().then((res) => {
      updateCartState({ taxes: cart?.taxes, ...res.data });
      setCouponCode(''); // Reset input field
    });
  };

  return {
    appliedCouponCode,
    appliedPercentOff,
    buttonDisabled,
    couponCode,
    couponError,
    submittingCoupon,
    clearCoupon,
    handleOnChange,
    submitCouponCode,
  };
};

export default useCartCoupon;
